.card__testimonials-slider_one--wrap{
    width: 416px;
    margin: 0 auto;
}

.card__testimonials-slider_one, 
.card__testimonials-slider_two {
    padding: 2.375rem;
    border-radius: 30px;
    background: #ffffff;
    width: 100%;
}

.card__testimonials-slider_body {
    //height: fit-content;
}

.card__testimonials-slider_desc--wrap {
    padding: 0 5px 0 2px;
}

.card__testimonials-slider_desc {
    //margin-bottom: 0;
    padding: 2px;
}

@media (max-width: 992px){
    .card__testimonials-slider_one--wrap{
        width: 100%;
    }
}
