
.card__our-clients_link{
    display: block;
}

.image__our-clients {
    width: 190px;
    height: 100px;
}

.image__our-clients img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
