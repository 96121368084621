.card__footer_brand-text{
    font-size: 0.8rem !important;
}

.btn-social{
    background: #edf1f5 !important;
    border: none !important;
}

.btn-social:hover{
    background: #448c74 !important;
}

.card__footer_nav-item a{
    color: #434a57 !important;
    font-weight: 500 !important;
}

.card__footer_nav-item a:hover{
    color: #448c74 !important;
}

.card__footer_nav-item--mail a{
    color: #448c74 !important;
    text-decoration: underline;
}

.card__footer_nav-item--mail a:hover{
    text-decoration: none;
}

.footer_copyright__link:hover{
    text-decoration: underline !important;
    color: #448c74 !important;
}
