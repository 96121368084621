.card__industries-slider_wrap{
    border-radius: 2rem !important;
    /*max-width: 416px;
    width: 100%;*/
    width: 416px;
    margin: 0 auto;
}

.card__industries-slider_image-wrap{
    /*max-width: 416px;
    width: 100%;*/
    width: 416px;
    max-height: 297px;
    height: 100%;
}

.card__industries-slider_image{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card__industries-slider__body{
    padding: 2.37rem !important;
    color: #576071 !important;
}

.card__industries-slider__body--title{
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 1rem;
    font-size: 1.5rem;
}

@media (max-width: 992px){
    .card__industries-slider_wrap,
    .card__industries-slider_image-wrap{
        width: 100%;
    }
}
