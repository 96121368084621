.nav-wrap{
   position: sticky;
   top: 0;
   background: #ffffff;
   z-index: 999;
}

.navbar-brand{
    font-size: 1.375rem !important;
    font-weight: 600 !important;
}

.link-nav_item{
    text-decoration: none;
    font-size: 17px;
    line-height: 24px;
    font-weight: 500;
    color: #858c97;
    padding: 1rem;
    margin: 0 0 0 0;
}

.link-nav_item + .link-nav_item{
    /*margin-left: 20px;*/
}

.link-nav_item.active{
    color: #448c74;
}

.link-nav_item:hover,
.navbar-expand-lg .navbar-nav .nav-link:hover,
.link-nav_item.dropdown-item:hover{
    text-decoration: none;
    color: #448c74;
}

.navbar-expand-lg .navbar-nav .nav-link{
    padding: 0;
}

.btn.btn-barnav{
    padding: 0.5rem 1.25rem !important;
    border-radius: 1rem !important;
    color: #ffffff !important;
}

.btn.btn-barnav:hover{
    color: #ffffff;
}
