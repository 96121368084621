.rating-number{
    font-size: 3rem !important;
    line-height: 1.3 !important;
    font-weight: 600 !important;
    color: #448c74 !important;
}

.rating-number span{
    font-size: 1rem !important;
    line-height: 1.5 !important;
    font-weight: 400 !important;
    color: #576071 !important;
}
