.card__estimation_image-wrap{
    max-width: 416px;
    width: 100%;
    height: 100%;
}

.card__estimation_image{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 3;
}

.card__estimation_subtitle,
.card__estimation_text{
    font-size: 22px;
}
