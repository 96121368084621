.forms{
    max-width: 420px;
    border: 1px solid rgb(215, 221, 226);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border-radius: 1rem;
}

.inputs-mail{
    max-width: 227px;
    width: 100%;
    border: none;
    background: transparent;
    padding: 0.85rem 1rem 0.85rem 0;
}

.inputs-name{
    max-width: 40px;
    width: 100%;
    border: none;
    text-align: center;
    background: transparent;
    padding: 0.7rem;
}

.buttons{
    max-width: 130px;
    width: 100%;
    border: none;
    background: rgb(55, 113, 93);
    color: rgb(255, 255, 255);
    border-radius: 1rem;
    padding: 0.82rem 0.75rem;
}

.buttons:hover{
    opacity: 0.8;
}
