body{
    background-color: #f6f9fc !important;
}

html,
body,
head,
#root {
  height: 100%;
  width: 100%;
  padding: 0px;
  margin: 0px;
}


a {
  color: inherit;
  text-decoration: none;
}

