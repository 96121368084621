.card__recent-studies_body{
    border-radius: 30px !important;
}

.card-body{
    padding: 2.37rem !important;
}

.card__recent-studies_image--wrap{
    margin-right: -25px;
}

.card__recent-studies_image{
    width: 330px;
    height: 252px;
}

.card__recent-studies_link{
    text-decoration: none !important;
    display: block;
    color: inherit;
    font-weight: 600;
    font-size: 1rem;
    padding: 1rem 0;
    width: fit-content;
    position: relative;
}

.card__recent-studies_link::after{
    content: '->';
    position: absolute;
    right: -30px;
    font-size: 1.2rem;
    line-height: 22px;
}
