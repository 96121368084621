/*section-one*/
.display-3{
    font-size: 4rem;
    font-weight: 600 !important;
    line-height: 1.3 !important;
}
/*End section-one*/

/*section-two*/
.rating-bg{
    background-color: #ffffff;
}
/*End section-two*/

/*section industries*/
.industries-slider__wrap{
    width: 100% !important;

    [class~="slick-dots"] {
        bottom: -3rem;
        left: 0;
        right: 0;        
      }
    
      [class~="slick-dots"] li button:before {
        font-size: 15px;        
      }  

      [class~="slick-dots"] li.slick-active button:before {
        opacity: .75;
    color: #448c74;        
      }  

      [class~="slick-dots"] li {
        margin: 0 10px;
      }
}

.industri_btn__wrap{
    margin-top: 1rem !important;    
}

.industri_btn{
    padding: 0.5rem 1.25rem !important;
    border-radius: 1rem !important;
    color: #ffffff !important;    
}
/*End section industries*/

/*section marquee*/
.section-marquee,
.section-marquee__wrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-marquee__list{
  background-color: #edf1f5;
    border-radius: 1rem;
}

.section-marquee .overlay{
  --gradient-color: none !important;
}

.section-marquee__wrap--title{
  margin-right: 20px;
}
/*End section marquee*/

/*section estimation*/
.estimstion-list{
  counter-reset: myCounter;
}

.estimstion-list__item{
  list-style: none;
  /*list-style-type: decimal-leading-zero;*/
  position: relative;
}

.estimstion-list__item:before {
  position: absolute;
  counter-increment: myCounter;
  content: '0' counter(myCounter);
  /*content:counter(myCounter);*/
  color: white;
  background: #37715d;
  border-radius: 50%;
  font-size: 1.3rem;
  font-weight: 700;
  display: inline-block;
  text-align: center;
  line-height: 56px;
  width: 56px;
  height: 56px;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}
/*End estimatiom*/

/*section testimonials*/
.testimonials-slider__wrap{
  width: 100% !important;

  [class~="slick-dots"] {
      bottom: -3rem;
      left: 0;
      right: 0;        
    }
  
    [class~="slick-dots"] li button:before {
      font-size: 15px;        
    }  

    [class~="slick-dots"] li.slick-active button:before {
      opacity: .75;
  color: #448c74;        
    }  

    [class~="slick-dots"] li {
      margin: 0 10px;
    }
}

.testimonials-slider__slide{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
/*End section testimonials*/
