.card__our-services_link{
    display: flex !important;
    flex-direction: column !important;
    justify-content: space-between !important;
    background-color: #ffffff !important;
    color: #576071 !important;
    border-radius: 1rem !important;
    text-decoration: none !important;
    transition: all .3s !important;
}

.card__our-services_link:hover{
    background-color: #448c74 !important;
    color: #ffffff !important;
    .btn-icons-arrow {
      -webkit-filter: grayscale(100) brightness(200);
      filter: grayscale(100) brightness(200);
    }
}

.card__body_our-services{
    padding: 2.37rem !important;
}

.image__our-services{
    width: 40px;
    height: 40px;
}

.image__our-services img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-title__our-services{
    margin-bottom: 1rem;
}

.card-footer{
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    
}

.btn-icons-arrow__wrap{
    width: 56px;
    height: 56px;
    display: block;
}

.btn-icons-arrow{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
