.forms__futer_form{
    max-width: 420px;
    border: 1px solid rgba(255, 255, 255, .25);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border-radius: 1rem;
}

.inputs-mail__futer_form{
    max-width: 227px;
    width: 100%;
    border: none;
    /*background: transparent;*/
    padding: 0.85rem 1rem 0.85rem 0;
    color: rgba(255, 255, 255, .7) !important;
}

.inputs-mail__futer_form::placeholder{
    color: rgba(255, 255, 255, .7) !important;
}

.inputs-name__futer_form{
    max-width: 40px;
    width: 100%;
    border: none;
    text-align: center;
    background: transparent;
    padding: 0.7rem;
    color: rgba(255, 255, 255, .7) !important;
}

.buttons-futer_form{
    max-width: 130px;
    width: 100%;
    border: none;
    /*background: rgb(55, 113, 93);*/
    color: rgb(255, 255, 255) !important;
    border-radius: 1rem !important;
    padding: 0.825rem 0.75rem !important;
    opacity: 0.8 !important;
}

.buttons-futer_form:hover{
    color: #ffffff !important;
    background-color: #e9c02b !important;
    opacity: 1 !important;
}
